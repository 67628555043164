input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.pdfdocument {
  margin-left: auto;
  margin-right: auto;
}

.customMapPinLabelClass {
  font-size: 12px !important;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center !important;
  z-index: -1;
  color: white !important;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

html {
  height: -webkit-fill-available;
}
